.SearchResults {
    width: 43%;
    height: 950px;
    overflow-y: scroll;
    padding: .88rem;
    background-color: rgba(1, 12, 63, 0.7);
    box-shadow: 0 4px 2px 2px #000000;
    
  }
  .h2-SearchResults {
    margin-top:35px;
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #6f6f6f;
    font-family: 'Poppins', sans-serif;
    font-size: 1.55rem;
    color: #fff;
    border-bottom: 1px solid #6f6f6f;
  }
  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  
  @media only screen and (max-width: 1020px) {
    .SearchResults {
      width: 90%;
      margin-bottom: 2rem;
    }
  }