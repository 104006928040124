@import url('https://fonts.googleapis.com/css?family=Monofett&display=swap');


#brand-text{
    font-family: 'Monofett';
    font-size: 40px;
}
#brand-pop {
    color: #FF643E;
}
#brand-si {
    color: #FFB253;
}
#brand-code {
    color: #00618D;
}

