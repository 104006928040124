@import url(https://fonts.googleapis.com/css?family=Monofett&display=swap);
.SearchBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6.94rem;
    margin-bottom: 6.33rem;
  }
  
  .SearchBar input {
    width: 287px;
    padding: .88rem 0;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-bottom: 2.22rem;
    color: #010c3f;
    text-align: center;
    font-size: 1rem;
  }
  
  .SearchBar input:focus {
    outline: none;
  }
  
  .SearchButton {
    cursor: pointer;
    width: 8.11rem;
    padding: .77rem 0;
    border-radius: 54px;
    background-color: #010c3f;
    text-align: center;
    font-size: .833rem;
    transition: background-color .25s;
    border: 0px;
    color: #fff;
    font-weight: 500;
  }
  
  .SearchButton:hover {
    background-color: rgba(108, 65, 233, .7);
  }
.SearchResults {
    width: 43%;
    height: 950px;
    overflow-y: scroll;
    padding: .88rem;
    background-color: rgba(1, 12, 63, 0.7);
    box-shadow: 0 4px 2px 2px #000000;
    
  }
  .h2-SearchResults {
    margin-top:35px;
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #6f6f6f;
    font-family: 'Poppins', sans-serif;
    font-size: 1.55rem;
    color: #fff;
    border-bottom: 1px solid #6f6f6f;
  }
  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  
  @media only screen and (max-width: 1020px) {
    .SearchResults {
      width: 90%;
      margin-bottom: 2rem;
    }
  }
.TrackList {
    width: 100%;
  }
h3 {
  color: white;
  font-size: 20px;
}

.Track {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(256, 256, 256, 0.8);
}

.Track-action {
  cursor: pointer;
  padding: .5rem;
  font-size: 1.05rem;
  transition: color .25s;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.Track-action:hover {
  color: rgba(265, 265, 265, .5);
}

.Track-information {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 72px;
  padding: 0;
}

.Track-information h3 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.Track-information p {
  font-size: .83rem;
  font-weight: 300;
  color: rgba(256, 256, 256, 0.8);
  margin-top: 0;
  padding-top: 0;
}
.Playlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    width: 43%;
    max-height: 950px;
    padding: 2.27rem 1.16rem;
    background-color: rgba(1, 12, 63, 0.7);
    box-shadow: 0 4px 2px 2px #000000;
  }
  
  .Playlist input {
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid #6f6f6f;
    font-family: 'Poppins', sans-serif;
    font-size: 1.55rem;
    color: #fff;
  }
  
  .Playlist-save {
    cursor: pointer;
    width: 10rem;
    padding: .77rem 0;
    border-radius: 54px;
    border-width: 0px;
    margin-top: 1.27rem;
    background-color: #6c41ec;
    text-align: center;
    font-size: .83rem;
    transition: background-color .25s;
    color: #fff;
    font-weight: 500;
  }
  
  .Playlist-save:hover {
    background-color: rgba(108, 65, 233, .7);
  }
  
  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  
  @media only screen and (max-width: 1020px) {
    .Playlist {
      width: 90%;
    }
  }
body,
html,
#root {
  height: 100%;
}

html {
  font-size: 18px;
}

h1 {
  padding: .77rem 0;
  background-color: #010c3f;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.88rem;
  color: #fff;
}

.h1-highlight {
  color: #6c41ec;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.55rem;
}

.App {
  height: 100%;
  padding: 0 17% 10% 17%;
  background-image: url(/static/media/background_photo_desktop.37be7357.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  color: #fff;
}

.App-playlist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 1020px) {
  .App-playlist {
    align-items: center;
    flex-direction: column;
  }
}
#brand-text{
    font-family: 'Monofett';
    font-size: 40px;
}
#brand-pop {
    color: #FF643E;
}
#brand-si {
    color: #FFB253;
}
#brand-code {
    color: #00618D;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

